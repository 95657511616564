<!-- 额度汇总 -->
<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-page-inner title="额度汇总">
        <el-col :span="24">
          <div style="height: 430px;">
            <div class="detail-area" style="height:100%;">
              <div id="chart-inner" class="detail-inner" style="width:90%;height: 80%; margin-top: 30px" />
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <div style="height: 430px;">
            <div class="detail-area" style="height:100%;">
              <div id="chart-inner1" class="detail-inner" style="width:90%;height: 80%; margin-top: 30px" />
            </div>
          </div>
        </el-col>
      </ics-page-inner>
    </div>
  </el-main>
</template>
<script>
import * as echarts from 'echarts/core'
import { routeEnterMixin } from '@/assets/js/mixins'
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent, MarkLineComponent, DataZoomComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { EleResize } from '@/assets/js/esresize'
import IcsPageInner from "../../../components/page-inner";
echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LegendComponent, MarkLineComponent, BarChart, CanvasRenderer, DataZoomComponent]
)
export default {
  components: {IcsPageInner},
  mixins: [routeEnterMixin],
  data () {
    return {
      chart: null,
      tableList: [],
      queryParams: {
        barInCount: undefined,
        barOutCount: undefined,
        barDate: undefined
      },
      bar_color: ['#FF9393', '#42BFAC', '#6B9BB8'],
      x1_color: ['#9EC8DA', '#D7AF88', '#A6D279', '#EBDAD3', '#EEE7DD', '#B0C4DE', '#4150d8', '#CCFF99', '#E6FFFD', '#D9B3FF', '#E3DBBF', '#B0E0E6',
        '#AFEEEE', '#FFE4E1', '#FFE4C4', '#FFE4B5', '#FAFAD2', '#98FB98', '#7FFFAA'],
      all_data: {
        legend: ['用信额', '申请中用信额度', '未使用授信']
      }
    }
  },
  watch: {},
  mounted () {
    this.initChart()
    this.initChart1()
  },
  methods: {
    drawLine (dom, option) {
      const myChart = echarts.init(document.getElementById(dom))
      const resizeDiv = document.getElementById(dom)
      myChart.setOption(option)
      const listener = function () {
        myChart.resize()
      }
      EleResize.on(resizeDiv, listener)
    },
    setOptions (option) {
      if (this.chart) this.chart.setOption(option)
    },
    initChart () {
      const dom = 'chart-inner'
      this.chart = echarts.init(document.getElementById('chart-inner'))
      // 指定图表的配置项和数据
      this.api.dashboard.coreQuotaChart().then(res => {
        const data = res.data.data || []
        const chartNameData = []
        const notUsedAmountData = []
        const totalAmountData = []
        const usedAmountData = []
        console.log(data)
        data.forEach(item => {
          chartNameData.push(`${item.coreName}（${item.capName}）`)
          notUsedAmountData.push(item.notUsedAmount)
          totalAmountData.push(item.totalAmount)
          usedAmountData.push(item.usedAmount)
        })
        const option = {
          title: {
            text: '核心企业额度汇总统计图',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          dataZoom: [{
            show: true,
            type: 'slider',
            height: 10,
            filterMode: 'none',
            xAxisIndex: [
              0, 1, 2
            ],
            bottom: 1,
            startValue: 0,
            endValue: 3,
            handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
            handleSize: '110%',
            handleStyle: {
              color: '#d3dee5'
            },
            textStyle: { color: '#fff' },
            borderColor: '#90979c'
          }],
          grid: {
            left: '3%',
            right: '4%',
            bottom: '70px',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              axisLabel: {
                interval: 0,
              },
              data: chartNameData || [],
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '核心企业总额度',
              type: 'bar',
              stack: 'Ad',
              barWidth : 70,
              emphasis: {
                focus: 'series'
              },
              data: totalAmountData || []
            },
            {
              name: '未使用核心企业额度',
              type: 'bar',
              stack: 'Ad',
              barWidth : 70,
              emphasis: {
                focus: 'series'
              },
              data: notUsedAmountData || []
            },
            {
              name: '使用中核心企业额度',
              type: 'bar',
              stack: 'Ad',
              barWidth : 70,
              emphasis: {
                focus: 'series'
              },
              data: usedAmountData || []
            }
          ]
        }
        this.drawLine(dom, option)
      })
    },
    initChart1 () {
      const dom = 'chart-inner1'
      this.chart = echarts.init(document.getElementById('chart-inner1'))
      // 指定图表的配置项和数据
      this.api.dashboard.customerQuotaChart().then(res => {
        const data = res.data.data || []
        const chartNameData = []
        const notUsedAmountData = []
        const totalAmountData = []
        const usedAmountData = []
        data.forEach(item => {
          chartNameData.push(`${item.customerName}（${item.capName}）`)
          notUsedAmountData.push(item.notUsedAmount)
          totalAmountData.push(item.totalAmount)
          usedAmountData.push(item.usedAmount)
        })
        const option = {
          title: {
            text: '融资企业额度汇总统计图',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          dataZoom: [{
            show: true,
            type: 'slider',
            height: 10,
            filterMode: 'none',
            xAxisIndex: [
              0
            ],
            bottom: 2,
            startValue: 0,
            endValue: 3,
            handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
            handleSize: '110%',
            handleStyle: {
              color: '#d3dee5'
            },
            textStyle: { color: '#fff' },
            borderColor: '#90979c'
          }],
          grid: {
            left: '3%',
            right: '4%',
            bottom: '70px',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              axisLabel: {
                interval: 0,
              },
              data: chartNameData || [],
            },
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '融资企业总额度',
              type: 'bar',
              stack: 'Ad',
              barWidth : 70,
              emphasis: {
                focus: 'series'
              },
              data: totalAmountData || []
            },
            {
              name: '未使用融资企业额度',
              type: 'bar',
              stack: 'Ad',
              barWidth : 70,
              emphasis: {
                focus: 'series'
              },
              data: usedAmountData || []
            },
            {
              name: '使用中融资企业额度',
              type: 'bar',
              stack: 'Ad',
              barWidth : 70,
              emphasis: {
                focus: 'series'
              },
              data: notUsedAmountData || []
            }
          ]
        }
        this.drawLine(dom, option)
      })
    }
  }
}
</script>
<style>
</style>
