var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title"),
      _c(
        "div",
        { staticClass: "partition-area" },
        [
          _c(
            "ics-page-inner",
            { attrs: { title: "额度汇总" } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticStyle: { height: "430px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "detail-area",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c("div", {
                        staticClass: "detail-inner",
                        staticStyle: {
                          width: "90%",
                          height: "80%",
                          "margin-top": "30px"
                        },
                        attrs: { id: "chart-inner" }
                      })
                    ]
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticStyle: { height: "430px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "detail-area",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c("div", {
                        staticClass: "detail-inner",
                        staticStyle: {
                          width: "90%",
                          height: "80%",
                          "margin-top": "30px"
                        },
                        attrs: { id: "chart-inner1" }
                      })
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }